var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
var ctxs = new Map();
var providers = new Map();
export function useStoreContext(Store) {
    var ctx = ctxs.get(Store);
    if (!ctx) {
        throw new Error("".concat(Store.name, " context not found"));
    }
    var result = React.useContext(ctx);
    if (result === undefined) {
        throw new Error("".concat(Store.name, " must be defined"));
    }
    return result;
}
export var useProvider = function (Store) {
    var ctx = ctxs.get(Store);
    if (!ctx) {
        ctx = React.createContext(undefined);
        ctx.displayName = Store.name;
        ctxs.set(Store, ctx);
    }
    var CtxProvider = ctx.Provider;
    var Provider = providers.get(Store);
    if (!Provider) {
        Provider = function ProviderComponent(_a) {
            var children = _a.children, value = _a.value;
            React.useEffect(function () {
                var _a;
                (_a = value.init) === null || _a === void 0 ? void 0 : _a.call(value);
                return function () {
                    var _a;
                    (_a = value.dispose) === null || _a === void 0 ? void 0 : _a.call(value);
                };
            }, [value]);
            return (React.createElement(CtxProvider, { value: value }, children));
        };
        providers.set(Store, Provider);
    }
    return Provider;
};
export var useStore = function (Store) {
    var deps = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        deps[_i - 1] = arguments[_i];
    }
    return React.useMemo(function () { return (new (Store.bind.apply(Store, __spreadArray([void 0], deps, false)))()); }, deps);
};
