var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { makeAutoObservable, toJS } from 'mobx';
import { getSafeProcessingId } from '@/utils/get-safe-processing-id';
export var PositionEditType;
(function (PositionEditType) {
    PositionEditType[PositionEditType["TakeProfit"] = 0] = "TakeProfit";
    PositionEditType[PositionEditType["StopLoss"] = 1] = "StopLoss";
})(PositionEditType || (PositionEditType = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["WrapSuccess"] = 0] = "WrapSuccess";
    NotificationType[NotificationType["UnwrapSuccess"] = 1] = "UnwrapSuccess";
    NotificationType[NotificationType["ApproveSuccess"] = 2] = "ApproveSuccess";
})(NotificationType || (NotificationType = {}));
var initialState = {
    list: [],
};
var NotificationsStore = /** @class */ (function () {
    function NotificationsStore() {
        this.state = initialState;
        makeAutoObservable(this, {}, {
            autoBind: true,
        });
    }
    NotificationsStore.prototype.push = function (notification) {
        this.state.list = __spreadArray([__assign({ id: getSafeProcessingId() }, notification)], this.state.list, true);
    };
    NotificationsStore.prototype.close = function (id) {
        this.state.list = this.state.list.filter(function (item) { return item.id !== id; });
    };
    Object.defineProperty(NotificationsStore.prototype, "list", {
        get: function () {
            return toJS(this.state.list).reverse();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotificationsStore.prototype, "last", {
        get: function () {
            return this.list[this.list.length - 1];
        },
        enumerable: false,
        configurable: true
    });
    return NotificationsStore;
}());
export { NotificationsStore };
