var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import * as React from 'react';
import CloseIcon from '@/icons/close.svg';
import styles from './index.module.scss';
export function Notification(_a) {
    var _b;
    var title = _a.title, message = _a.message, _c = _a.type, type = _c === void 0 ? 'default' : _c, props = __rest(_a, ["title", "message", "type"]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var _d = React.useState(), timerId = _d[0], setTimerId = _d[1];
    var _e = React.useState(false), mouseEntered = _e[0], setMouseEntered = _e[1];
    var _f = React.useState(false), hidden = _f[0], setHidden = _f[1];
    var onClose = function () {
        setHidden(true);
        setTimeout(function () {
            var _a;
            (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
        }, 250);
    };
    var onMouseEnter = function () {
        clearTimeout(timerId);
        setMouseEntered(true);
    };
    React.useEffect(function () {
        var timer = setTimeout(onClose, 7000);
        setTimerId(timer);
        return function () {
            clearTimeout(timer);
        };
    }, []);
    return (React.createElement("div", { onMouseEnter: onMouseEnter, className: classNames(styles.notification, styles[type], (_b = {},
            _b[styles.hidden] = hidden,
            _b[styles.mouseEntered] = mouseEntered,
            _b)) },
        props.onClose && (React.createElement("button", { className: styles.close, onClick: onClose },
            React.createElement(CloseIcon, null))),
        title && (React.createElement("div", { className: styles.title }, title)),
        message && (React.createElement("div", { className: styles.message }, message))));
}
