var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import tokenAbi from '@/abi/Token.abi.json';
import { DataSync } from '@/stores/DataStore';
import { NotificationType } from '@/stores/NotificationsStore';
import { getEvmTokenBalance } from '@/utils/misc';
import { normalizeAmount } from '@/utils/normalize-amount';
import { Reactions } from '@/utils/reactions';
import { web3Modal } from '@/web3modal';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
var initialState = {
    loader: 0,
};
var UnwrapFormStore = /** @class */ (function () {
    function UnwrapFormStore(wallet, config, notifications) {
        this.wallet = wallet;
        this.config = config;
        this.notifications = notifications;
        this.state = initialState;
        this.tokenDecimals = 9;
        this.reactions = new Reactions();
        this.newTokenBalance = new DataSync(getEvmTokenBalance);
        makeAutoObservable(this, {}, {
            autoBind: true,
        });
    }
    UnwrapFormStore.prototype.init = function () {
        var _this = this;
        this.reactions.create(reaction(function () { return [
            _this.wallet.blockNum,
            _this.config.rpcUrl,
            _this.config.newToken,
            _this.wallet.address,
            _this.loading,
        ]; }, function () {
            if (!_this.loading) {
                if (_this.config.rpcUrl && _this.config.newToken && _this.wallet.address) {
                    _this.newTokenBalance.sync(_this.config.rpcUrl, _this.config.newToken, _this.wallet.address);
                }
                else {
                    _this.newTokenBalance.reset();
                }
            }
        }, {
            fireImmediately: true,
        }));
    };
    UnwrapFormStore.prototype.setAmount = function (amount) {
        this.state.amount = amount;
    };
    UnwrapFormStore.prototype.submit = function () {
        return __awaiter(this, void 0, Promise, function () {
            var error, amount, provider, browserProvider, signer, contract, trx, e_1;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        amount = this.amount;
                        runInAction(function () {
                            _this.state.loader += 1;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        provider = web3Modal.getWalletProvider();
                        if (!provider) {
                            throw new Error('provider must be defined');
                        }
                        if (!this.config.newToken) {
                            throw new Error('config.wrapper must be defined');
                        }
                        browserProvider = new ethers.BrowserProvider(provider);
                        return [4 /*yield*/, browserProvider.getSigner()];
                    case 2:
                        signer = _b.sent();
                        contract = new ethers.Contract(this.config.newToken, tokenAbi, signer);
                        return [4 /*yield*/, contract.unwrap(this.amountNormalize)];
                    case 3:
                        trx = _b.sent();
                        return [4 /*yield*/, trx.wait()];
                    case 4:
                        _b.sent();
                        amount = undefined;
                        this.notifications.push({
                            type: NotificationType.UnwrapSuccess
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _b.sent();
                        console.error(e_1);
                        if (e_1 && e_1.code !== 'ACTION_REJECTED') {
                            error = (_a = e_1.stack) !== null && _a !== void 0 ? _a : 'Unknown error';
                        }
                        return [3 /*break*/, 6];
                    case 6:
                        runInAction(function () {
                            _this.state.error = error;
                            _this.state.loader -= 1;
                            _this.state.amount = amount;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(UnwrapFormStore.prototype, "amount", {
        get: function () {
            return this.state.amount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnwrapFormStore.prototype, "amountNormalize", {
        get: function () {
            return this.state.amount ? normalizeAmount(this.state.amount, this.tokenDecimals) : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnwrapFormStore.prototype, "balance", {
        get: function () {
            return this.newTokenBalance.value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnwrapFormStore.prototype, "valid", {
        get: function () {
            if (this.balance && this.amountNormalize) {
                return new BigNumber(this.amountNormalize).gt(0)
                    && new BigNumber(this.balance).gt(0)
                    && new BigNumber(this.balance).gte(this.amountNormalize);
            }
            return undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnwrapFormStore.prototype, "error", {
        get: function () {
            return this.state.error;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnwrapFormStore.prototype, "loading", {
        get: function () {
            return this.state.loader > 0;
        },
        enumerable: false,
        configurable: true
    });
    return UnwrapFormStore;
}());
export { UnwrapFormStore };
