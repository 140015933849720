import * as React from 'react';
import { Button } from '@/components/Button';
import useClipboard from 'react-use-clipboard';
import styles from './index.module.scss';
export var ErrorMsg = function (_a) {
    var error = _a.error;
    var _b = useClipboard(error !== null && error !== void 0 ? error : '', { successDuration: 1000 }), isCopied = _b[0], setCopied = _b[1];
    return error
        ? (React.createElement("div", { className: styles.error },
            React.createElement("pre", { className: styles.code },
                React.createElement("code", null, error)),
            React.createElement(Button, { size: 's', type: 'seconday', onClick: setCopied }, isCopied ? 'Copied' : 'Copy')))
        : null;
};
