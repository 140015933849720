import * as React from 'react';
import { Button } from '@/components/Button';
import { NETWORKS } from '@/config';
import { useStoreContext } from '@/hooks/useStore';
import DisconnectIcon from '@/icons/disconnect.svg';
import { WalletStore } from '@/stores/WalletStore';
import { formattedTokenAmount, sliceAddress } from '@broxus/js-utils';
import { observer } from 'mobx-react-lite';
import styles from './index.module.scss';
export var Wallet = observer(function () {
    var wallet = useStoreContext(WalletStore);
    var coin = React.useMemo(function () { var _a, _b; return (_b = (_a = NETWORKS.find(function (item) { return item.chain.chainId === wallet.networkId; })) === null || _a === void 0 ? void 0 : _a.chain.currency) !== null && _b !== void 0 ? _b : ''; }, [wallet.networkId]);
    var network = React.useMemo(function () { var _a, _b; return (_b = (_a = NETWORKS.find(function (item) { return item.chain.chainId === wallet.networkId; })) === null || _a === void 0 ? void 0 : _a.chain.name) !== null && _b !== void 0 ? _b : ''; }, [wallet.networkId]);
    return (React.createElement("div", { className: styles.root }, wallet.address
        ? (React.createElement("div", { className: styles.user },
            React.createElement("div", { className: styles.item },
                React.createElement("div", null, network),
                React.createElement("div", { className: styles.balance }, '\u200b')),
            React.createElement("div", { className: styles.item },
                React.createElement("div", null, sliceAddress(wallet.address)),
                React.createElement("div", { className: styles.balance }, wallet.balance
                    ? "".concat(formattedTokenAmount(wallet.balance, wallet.decimals), " ").concat(coin)
                    : '\u200b')),
            React.createElement(Button, { type: 'seconday', className: styles.logout, onClick: wallet.disconnect },
                React.createElement(DisconnectIcon, null))))
        : (React.createElement(Button, { type: 'seconday', onClick: wallet.connect }, "Connect EVM Wallet"))));
});
