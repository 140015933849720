var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useStoreContext } from '@/hooks/useStore';
import { NotificationsStore, NotificationType } from '@/stores/NotificationsStore';
import { Notification } from '@/components/Notification';
import styles from './index.module.scss';
function NotificationsInner() {
    var notifications = useStoreContext(NotificationsStore);
    var onCloseFn = function (id) { return function () {
        notifications.close(id);
    }; };
    return ReactDOM.createPortal(React.createElement("div", { className: styles.list }, notifications.list.map(function (item) {
        var defaultProps = {
            key: item.id,
            onClose: onCloseFn(item.id),
        };
        switch (item.type) {
            case NotificationType.WrapSuccess:
                return (React.createElement(Notification, __assign({}, defaultProps, { type: "success", title: "Wrap success" })));
            case NotificationType.UnwrapSuccess:
                return (React.createElement(Notification, __assign({}, defaultProps, { type: "success", title: "Unwrap success" })));
            case NotificationType.ApproveSuccess:
                return (React.createElement(Notification, __assign({}, defaultProps, { type: "success", title: "Approve success" })));
            default:
                return null;
        }
    })), document.body);
}
export var Notifications = observer(NotificationsInner);
