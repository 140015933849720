import { NETWORKS } from '@/config';
import { makeAutoObservable } from 'mobx';
var ConfigStore = /** @class */ (function () {
    function ConfigStore(wallet) {
        this.wallet = wallet;
        this.tokenDecimals = 9;
        makeAutoObservable(this, {}, {
            autoBind: true,
        });
    }
    Object.defineProperty(ConfigStore.prototype, "config", {
        get: function () {
            var _this = this;
            if (this.wallet.networkId) {
                return NETWORKS.find(function (item) { return item.chain.chainId === _this.wallet.networkId; });
            }
            return undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "oldToken", {
        get: function () {
            var _a;
            return (_a = this.config) === null || _a === void 0 ? void 0 : _a.oldToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "newToken", {
        get: function () {
            var _a;
            return (_a = this.config) === null || _a === void 0 ? void 0 : _a.newToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "rpcUrl", {
        get: function () {
            var _a;
            return (_a = this.config) === null || _a === void 0 ? void 0 : _a.chain.rpcUrl;
        },
        enumerable: false,
        configurable: true
    });
    return ConfigStore;
}());
export { ConfigStore };
