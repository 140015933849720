import { NETWORKS } from '@/config';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
// 1. Your WalletConnect Cloud project ID
var projectId = '4257951d78ad91eab174036051c4f5a3';
var metadata = {
    name: 'Wrapped EVER',
    description: 'Wrap and unwrap EVER tokens for use on the Octus Bridge and FlatQube DEX',
    url: 'https://swap.wrappedever.io', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
};
var ethersConfig = defaultConfig({
    /* Required */
    metadata: metadata,
    /* Optional */
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 1, // used for the Coinbase SDK
});
export var web3Modal = createWeb3Modal({
    ethersConfig: ethersConfig,
    projectId: projectId,
    chains: NETWORKS.map(function (item) { return item.chain; }),
    enableAnalytics: false, // Optional - defaults to your Cloud configuration
});
