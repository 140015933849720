import { useProvider, useStore } from '@/hooks/useStore';
import { WalletStore } from '@/stores/WalletStore';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Form } from '@/components/Form';
import { Wallet } from '@/components/Wallet';
import { ConfigStore } from '@/stores/ConfigStore';
import { UnwrapFormStore } from '@/stores/UnwrapFormStore';
import { WrapFormStore } from '@/stores/WrapFormStore';
import styles from './index.module.scss';
import { Notifications } from '@/components/Notifications';
import { NotificationsStore } from '@/stores/NotificationsStore';
export var App = observer(function () {
    var notifications = useStore(NotificationsStore);
    var NotificationsProvider = useProvider(NotificationsStore);
    var wallet = useStore(WalletStore);
    var WalletProvider = useProvider(WalletStore);
    var config = useStore(ConfigStore, wallet);
    var ConfigProvider = useProvider(ConfigStore);
    var wrapForm = useStore(WrapFormStore, wallet, config, notifications);
    var WrapFormProvider = useProvider(WrapFormStore);
    var unwrapForm = useStore(UnwrapFormStore, wallet, config, notifications);
    var UnwrapFormProvider = useProvider(UnwrapFormStore);
    return (React.createElement(NotificationsProvider, { value: notifications },
        React.createElement(ConfigProvider, { value: config },
            React.createElement(WalletProvider, { value: wallet },
                React.createElement(WrapFormProvider, { value: wrapForm },
                    React.createElement(UnwrapFormProvider, { value: unwrapForm },
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.app },
                                React.createElement("div", { className: styles.header },
                                    React.createElement("div", { className: styles.logo }, "Wrapped Ever EVM"),
                                    React.createElement(Wallet, null)),
                                React.createElement("div", { className: styles.body },
                                    React.createElement(Form, null))),
                            React.createElement(Notifications, null))))))));
});
