import * as React from 'react';
import { Button } from '@/components/Button';
import { ErrorMsg } from '@/components/ErrorMsg';
import { Input } from '@/components/Input';
import { Loader } from '@/components/Loader';
import { useStoreContext } from '@/hooks/useStore';
import { ConfigStore } from '@/stores/ConfigStore';
import { UnwrapFormStore } from '@/stores/UnwrapFormStore';
import { WalletStore } from '@/stores/WalletStore';
import { WrapFormStore } from '@/stores/WrapFormStore';
import { formattedTokenAmount } from '@broxus/js-utils';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import styles from './index.module.scss';
var FormType;
(function (FormType) {
    FormType[FormType["Wrap"] = 0] = "Wrap";
    FormType[FormType["Unwrap"] = 1] = "Unwrap";
})(FormType || (FormType = {}));
export var Form = observer(function () {
    var _a, _b, _c;
    var wrapForm = useStoreContext(WrapFormStore);
    var unwrapForm = useStoreContext(UnwrapFormStore);
    var wallet = useStoreContext(WalletStore);
    var config = useStoreContext(ConfigStore);
    var _d = React.useState(FormType.Unwrap), formType = _d[0], setFormType = _d[1];
    return (React.createElement("form", { className: styles.root, onSubmit: function (e) {
            e.preventDefault();
            if (formType === FormType.Wrap) {
                if (wrapForm.needApprov) {
                    wrapForm.approveTokens();
                }
                else {
                    wrapForm.submit();
                }
            }
            else {
                unwrapForm.submit();
            }
        } },
        React.createElement("div", { className: classNames(styles.tabs, (_a = {},
                _a[styles.single] = !wallet.canWrap,
                _a)) },
            wallet.canWrap && (React.createElement("button", { type: 'button', className: classNames(styles.tab, (_b = {},
                    _b[styles.active] = formType === FormType.Wrap,
                    _b)), onClick: function () {
                    setFormType(FormType.Wrap);
                } }, "Wrap EVER")),
            React.createElement("button", { type: 'button', className: classNames(styles.tab, (_c = {},
                    _c[styles.active] = formType === FormType.Unwrap,
                    _c)), onClick: function () {
                    setFormType(FormType.Unwrap);
                } }, "Unwrap WEVER")),
        formType === FormType.Wrap
            ? (React.createElement(Input, { key: "wrap", label: 'Amount to wrap', balance: wrapForm.balance
                    ? formattedTokenAmount(wrapForm.balance, config.tokenDecimals)
                    : undefined, onAmount: wrapForm.setAmount, value: wrapForm.amount, disbled: wrapForm.loading }))
            : (React.createElement(Input, { key: "unwrap", label: 'Amount to unwrap', balance: unwrapForm.balance
                    ? formattedTokenAmount(unwrapForm.balance, config.tokenDecimals)
                    : undefined, onAmount: unwrapForm.setAmount, value: unwrapForm.amount, disbled: unwrapForm.loading })),
        formType === FormType.Wrap ? React.createElement(ErrorMsg, { error: wrapForm.error }) : React.createElement(ErrorMsg, { error: unwrapForm.error }),
        wallet.address
            ? (formType === FormType.Wrap
                ? (wrapForm.needApprov
                    ? (React.createElement(Button, { key: "approvebtn", size: 'l', className: styles.submit, htmlType: 'submit', disabled: wrapForm.loading },
                        "Approve",
                        wrapForm.loading && React.createElement(Loader, null)))
                    : (React.createElement(Button, { key: "wrapbtn", size: 'l', className: styles.submit, htmlType: 'submit', disabled: !wrapForm.valid || wrapForm.loading },
                        "Wrap",
                        wrapForm.loading && React.createElement(Loader, null))))
                : (React.createElement(Button, { key: "unwrapbtn", size: 'l', className: styles.submit, htmlType: 'submit', disabled: !unwrapForm.valid || unwrapForm.loading },
                    "Unwrap",
                    unwrapForm.loading && React.createElement(Loader, null))))
            : (React.createElement(Button, { key: "connectbtn", size: 'l', className: styles.submit, htmlType: 'button', onClick: wallet.connect }, "Connect EVM Wallet"))));
});
