import * as React from 'react';
import { useAmountField } from '@/hooks/useAmountField';
import { useStoreContext } from '@/hooks/useStore';
import { ConfigStore } from '@/stores/ConfigStore';
import { observer } from 'mobx-react-lite';
import styles from './index.module.scss';
export var Input = observer(function (_a) {
    var label = _a.label, balance = _a.balance, onAmount = _a.onAmount, value = _a.value, disbled = _a.disbled;
    var config = useStoreContext(ConfigStore);
    var field = useAmountField({
        decimals: config.tokenDecimals,
        onBlur: onAmount,
        onChange: onAmount,
    });
    return (React.createElement("div", { className: styles.root },
        React.createElement("div", { className: styles.header },
            React.createElement("div", { className: styles.label }, label),
            React.createElement("div", { className: styles.balance }, balance ? "Balance: ".concat(balance) : '\u200b')),
        React.createElement("div", { className: styles.body },
            React.createElement("input", { type: 'text', className: styles.input, placeholder: '0.0', value: value !== null && value !== void 0 ? value : '', onChange: field.onChange, onBlur: field.onBlur, disabled: disbled }),
            React.createElement("div", { className: styles.token }))));
});
// import * as React from 'react'
// import { useAmountField } from '@/hooks/useAmountField'
// import { useStoreContext } from '@/hooks/useStore'
// import { WrapFormStore, FormType } from '@/stores/WrapFormStore'
// import { formattedTokenAmount } from '@broxus/js-utils'
// import { observer } from 'mobx-react-lite'
// import styles from './index.module.scss'
// export const Input: React.FC = observer(() => {
//     const form = useStoreContext(WrapFormStore)
//     const field = useAmountField({
//         decimals: form.tokenDecimals,
//         onBlur: form.setAmount,
//         onChange: form.setAmount,
//     })
//     const balance = form.type === FormType.Wrap
//         ? form.oldTokenBalance.value
//         : form.newTokenBalance.value
//     return (
//         <div className={styles.root}>
//             <div className={styles.header}>
//                 <div className={styles.label}>
//                     Amount to {form.type === FormType.Wrap ? 'wrap' : 'unwrap'}
//                 </div>
//                 <div className={styles.balance}>
//                     {balance ? `Balance: ${formattedTokenAmount(balance, form.tokenDecimals)}` : '\u200b'}
//                 </div>
//             </div>
//             <div className={styles.body}>
//                 <input
//                     type='text'
//                     className={styles.input}
//                     placeholder='0.0'
//                     value={form.amount ?? ''}
//                     onChange={field.onChange}
//                     onBlur={field.onBlur}
//                 />
//                 <div className={styles.token}>
//                 </div>
//             </div>
//         </div>
//     )
// })
