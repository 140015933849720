var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { truncateDecimals } from '@broxus/js-utils';
import BigNumber from 'bignumber.js';
export function useAmountField(_a) {
    var decimals = _a.decimals, defaultValue = _a.defaultValue, max = _a.max, min = _a.min, props = __rest(_a, ["decimals", "defaultValue", "max", "min"]);
    var onBlur = function (event) {
        var _a;
        var value = event.target.value;
        if (defaultValue && !value) {
            value = defaultValue;
        }
        else if (value) {
            var validatedAmount = truncateDecimals(value, decimals);
            value = validatedAmount != null ? validatedAmount : '';
        }
        var bn = value ? new BigNumber(value) : undefined;
        if (bn && max && bn.gt(max)) {
            value = max;
        }
        if (bn && min && bn.lt(min)) {
            value = min;
        }
        (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, value);
    };
    var onChange = function (event) {
        var _a;
        var value = event.target.value;
        if (event.nativeEvent.inputType === 'deleteByCut') {
            value = '';
        }
        else {
            value = value.replace(/[,]/g, '.');
            value = value.replace(/[.]+/g, '.');
            value = value.replace(/(?!- )[^0-9.]/g, '');
        }
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, value);
    };
    return { onBlur: onBlur, onChange: onChange };
}
