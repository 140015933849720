export var USERS_CAN_WRAP = ['0xf59592b00416faa0d7ea2fc7909be5222e18a482'];
export var NETWORKS = [{
        oldToken: '0x1ffEFD8036409Cb6d652bd610DE465933b226917',
        newToken: '0x0992544F6a5fdb52141a4E8840816be638f9C45d',
        chain: {
            chainId: 1,
            name: 'Ethereum',
            currency: 'ETH',
            explorerUrl: 'https://etherscan.io',
            rpcUrl: 'https://cloudflare-eth.com',
        },
    }];
