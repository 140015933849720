var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable class-methods-use-this */
import { ethers } from 'ethers';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { NETWORKS, USERS_CAN_WRAP } from '@/config';
import { lastOfCalls } from '@/utils/last-of-calls';
import { Reactions } from '@/utils/reactions';
import { web3Modal } from '@/web3modal';
var WalletStore = /** @class */ (function () {
    function WalletStore() {
        var _this = this;
        this.decimals = 18;
        this.reactions = new Reactions();
        this.state = {};
        this.setBlockNum = lastOfCalls(function (blockNum) {
            runInAction(function () {
                _this.state.blockNum = blockNum;
            });
        }, 300);
        this.syncBalance = lastOfCalls(function () { return __awaiter(_this, void 0, Promise, function () {
            var balance, provider, browserProvider, balanceInt, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        provider = web3Modal.getWalletProvider();
                        browserProvider = provider
                            ? new ethers.BrowserProvider(provider)
                            : undefined;
                        if (!(this.address && browserProvider)) return [3 /*break*/, 2];
                        return [4 /*yield*/, browserProvider.getBalance(this.address)];
                    case 1:
                        balanceInt = _a.sent();
                        balance = balanceInt.toString();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        runInAction(function () {
                            _this.state.balance = balance;
                        });
                        return [2 /*return*/];
                }
            });
        }); }, 300);
        makeAutoObservable(this, {}, {
            autoBind: true,
        });
    }
    WalletStore.prototype.init = function () {
        var _this = this;
        this.reactions.create(reaction(function () { return [_this.address, _this.networkId, _this.blockNum]; }, this.syncBalance.call, {
            fireImmediately: true,
        }), reaction(function () { return [_this.networkId]; }, this.initBlockListener, {
            fireImmediately: true,
        }));
        this.unsubscribeProvider = web3Modal.subscribeProvider(function (e) {
            if (e.chainId && !!NETWORKS.find(function (item) { return item.chain.chainId === e.chainId; })) {
                runInAction(function () {
                    _this.state.address = e.address;
                    _this.state.networkId = e.chainId;
                });
            }
            else {
                runInAction(function () {
                    _this.state.address = undefined;
                    _this.state.networkId = undefined;
                });
            }
        });
        this.unsubscribeState = web3Modal.subscribeState(function (e) {
            runInAction(function () {
                _this.state.isLoading = e.loading;
            });
        });
    };
    WalletStore.prototype.dispose = function () {
        var _a, _b;
        this.reactions.destroy();
        (_a = this.unsubscribeProvider) === null || _a === void 0 ? void 0 : _a.call(this);
        (_b = this.unsubscribeState) === null || _b === void 0 ? void 0 : _b.call(this);
    };
    WalletStore.prototype.initBlockListener = function () {
        var _a;
        (_a = this.browserProvider) === null || _a === void 0 ? void 0 : _a.removeAllListeners();
        if (this.networkId) {
            var provider = web3Modal.getWalletProvider();
            if (provider) {
                this.browserProvider = new ethers.BrowserProvider(provider);
                this.browserProvider.on('block', this.setBlockNum.call);
            }
        }
    };
    WalletStore.prototype.connect = function () {
        web3Modal.open();
    };
    WalletStore.prototype.disconnect = function () {
        web3Modal.open();
    };
    Object.defineProperty(WalletStore.prototype, "address", {
        get: function () {
            return this.state.address;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WalletStore.prototype, "balance", {
        get: function () {
            return this.state.balance;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WalletStore.prototype, "networkId", {
        get: function () {
            return this.state.networkId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WalletStore.prototype, "blockNum", {
        get: function () {
            return this.state.blockNum;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WalletStore.prototype, "isLoading", {
        get: function () {
            return !!this.state.isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WalletStore.prototype, "canWrap", {
        get: function () {
            return this.address
                ? USERS_CAN_WRAP.includes(this.address)
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    return WalletStore;
}());
export { WalletStore };
